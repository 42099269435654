import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ProgramsPage.scss";
import axios from "axios";

const ProgramsPage = () => {
  const dispatch = useDispatch();
  const [textData, setTextData] = useState(null);
  const lang = useSelector((s) => s.reducer.lang);

  useEffect(() => {
    axios(`https://biforum.kg/${lang}/api/v1/program/program_text/`).then(
      ({ data }) => {
        setTextData(data[0]);
      }
    );
  }, [dispatch, lang]);
  return (
    <div className="programs-page">
      <div className="banner">
        <h1>{
            lang === 'ru'
            ? 'Программа Форума'
            : lang === 'en'
            ? 'Forum Programm'
            : 'Форумдун Программасы'
            }</h1>
      </div>
      <div className="content">
        <div className="container">
          {textData ? (
            <p
              className="programs-page-text"
              dangerouslySetInnerHTML={{ __html: textData.descriptions }}
            ></p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgramsPage;
