import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import axios from 'axios';

const SET_PAGE_VISIBILITY = "SET_PAGE_VISIBILITY";
const SET_LANG = "SET_LANG";

const initialState = {
  lang: 'ru',
  domain: 'https://biforum.kg',
  isPageVisible: false, 
};

export const setLang = createAction(SET_LANG);
export const setPageVisibility = createAction(SET_PAGE_VISIBILITY);

export const fetchPageVisibility = createAsyncThunk(
  'page/fetchVisibility',
  async (_, { getState, dispatch }) => {
    const { domain, lang } = getState();
    try {
      const response = await axios.get(`${domain}/${lang}/api/page-visibility`);
      dispatch(setPageVisibility(response.data.isVisible));
    } catch (error) {
      console.error('Ошибка при получении видимости страницы:', error);
    }
  }
);

export default createReducer(initialState, (builder) => {
  builder
    .addCase(SET_LANG, (state, action) => {
      state.lang = action.payload;
    })
    .addCase(SET_PAGE_VISIBILITY, (state, action) => {
      state.isPageVisible = action.payload;
    });
});
